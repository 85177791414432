@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.modalOverlay {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	max-width: 400px;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	background-image: url("../../../assets/images/card/bgrMarket.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	animation: fadeIn 0.3s forwards;
}
.modalOverlay.show {
	opacity: 1;
	pointer-events: auto;
}

.main {
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 400px;
	align-items: center;
	justify-content: center;
	display: flex;
}
.body {
	position: absolute;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	row-gap: 20px;
	height: 96%;
}
.tagCardImg {
	position: absolute;
	top: -44px;
	width: 64%;
	height: 80px;
	left: 50%;
	transform: translateX(-50%);
}
.closeBtn {
	position: absolute;
	width: 70px;
	height: 32px;
	top: 20px;
	left: 20px;
	z-index: 1100;
}
.titleCard {
	position: absolute;
	top: -28px;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 20px;
	font-weight: 700;
}

.progressBarContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 88%;
	height: 20px;
	background: #39346d;
	border-radius: 25px;
}

.progressBar {
	width: 100%;
	height: 100%;
	border-radius: 25px;
	position: absolute;
	border-radius: 38px;
	background: linear-gradient(130deg, #ffed4e -12.64%, #c36507 116.46%);
	top: 0;
	left: 0;
	overflow: hidden;
}

.progressText {
	position: absolute;
	z-index: 1;
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 0.800000011920929;
	-webkit-text-stroke-color: #39346d;
	font-family: "BerlinSansFBDemi";
	font-size: 11.375px;
	font-weight: 700;
	text-transform: uppercase;
}
.titleItem {
	color: #39346d;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
}
.imgItemStyle {
	top: -10px;
	position: absolute;
	width: 70px;
	height: 70px;
}
.item {
	padding: 6px 10px;
	margin-top: 60px;
	padding-bottom: 10px;
	position: relative;
	justify-content: space-between;
	flex-direction: row;
	width: 80%;
	background: linear-gradient(90deg, #f0c694 0%, #fcddae 100%);
	display: flex;
	align-items: center;
}
.rightItem {
	width: 78%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.leftItem {
	width: 22%;
}
.leftItemText {
	margin-top: 60px;
	padding-bottom: 10px;
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #39346d;
	font-family: "BerlinSansFBDemi";
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
}
.bodyHeader::-webkit-scrollbar {
	width: 0;
}
.gallery {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	justify-content: center;
	overflow: auto;
}
.gallery::-webkit-scrollbar {
	width: 0;
}
.itemCard {
	position: relative;
	margin-top: 10px;
}
.image {
	width: 100px;
	border: 4px solid white;
	border-radius: 10px;
}
.imageDisable {
	width: 100px;
	border: 4px solid white;
	border-radius: 10px;
	filter: grayscale(100%);
}
.startStyle {
	width: 100px;
	display: flex;
	position: absolute;
	justify-content: center;
	top: -7px;
}
.amountStyle {
	background-image: url("../../../assets/roll/card/rectangle.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 40px;
	position: absolute;
	bottom: 30px;
	left: -6px;
}
.amountTextStyle {
	color: #9b3202;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 700;
}
.cardNameStyle {
	width: 100%;
	bottom: 3px;
	border-radius: 0px 0px 9.13px 10px;
	background: #000;
	padding: 4px 0;
	opacity: 0.8;
	position: absolute;
	z-index: 2000;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.notice1 {
	display: inline-flex;
	padding: 10px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
	top: 50%;
	z-index: 2000;
}
