@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

// .modalOverlay.show {
// 	opacity: 1;
// 	pointer-events: auto;
// }
.main {
	margin-top: 30px;
	position: relative;
	height: 88%;
	width: 100%;
	max-width: 400px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}
.body {
	margin-top: 20px;
	position: absolute;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	row-gap: 20px;
}
.tagCardImg {
	position: absolute;
	top: -44px;
	width: 64%;
	height: 80px;
	left: 50%;
	transform: translateX(-50%);
}
.closeBtn {
	position: absolute;
	width: 40px;
	height: 40px;
	top: -20px;
	right: 20px;
}
.titleCard {
	position: absolute;
	top: -28px;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 20px;
	font-weight: 700;
}

.progressBarContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	height: 20px;
	background: #39346d;
	border-radius: 25px;
}

.progressBar {
	width: 100%;
	height: 100%;
	border-radius: 25px;
	position: absolute;
	border-radius: 38px;
	background: linear-gradient(130deg, #ffed4e -12.64%, #c36507 116.46%);
	top: 0;
	left: 0;
	overflow: hidden;
}

.progressText {
	position: absolute;
	z-index: 1;
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 0.800000011920929;
	-webkit-text-stroke-color: #39346d;
	font-family: "BerlinSansFBDemi";
	font-size: 11.375px;
	font-weight: 700;
	text-transform: uppercase;
}
.titleItem {
	color: #39346d;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
}
.imgItemStyle {
	top: -10px;
	position: absolute;
	width: 80px;
	height: 80px;
}
.item {
	padding: 6px 10px;
	margin-top: 60px;
	position: relative;
	justify-content: space-between;
	flex-direction: row;
	width: 80%;
	border-radius: 8px;
	border: 1px solid #fff;
	background: linear-gradient(90deg, #f0c694 0%, #fcddae 100%);
	display: flex;
	align-items: center;
}
.rightItem {
	width: 70%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.leftItem {
	width: 25%;
}
.leftItemText {
	margin-top: 66px;
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #39346d;
	font-family: "BerlinSansFBDemi";
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
}
.bodyHeader::-webkit-scrollbar {
	width: 0;
}
.gallery {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	justify-content: center;
	overflow: auto;
}
.gallery::-webkit-scrollbar {
	width: 0;
}
.itemCard {
	position: relative;
	margin-top: 10px;
}
.image {
	width: 28vw;
	max-width: 50px;
	height: 36vw;
	border: 4px solid white;
	border-radius: 10px;
}
.imageDisable {
	width: 34vw;
	max-width: 150px;
	height: 36vw;
	max-height: 200px;
	border: 4px solid white;
	border-radius: 10px;
	filter: grayscale(100%);
	cursor: not-allowed;
}
.startStyle {
	width: 28vw;
	max-width: 150px;
	display: flex;
	position: absolute;
	justify-content: center;
	top: -12px;
}
.amountStyle {
	background-image: url("../../../assets/roll/card/rectangle.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 40px;
	position: absolute;
	bottom: 25px;
	left: -6px;
}
.amountTextStyle {
	color: #9b3202;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 700;
}

.textgot {
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 0.5;
	-webkit-text-stroke-color: #b89b73;
	font-family: "BerlinSansFBDemi";
	font-size: 28px;
	font-weight: 700;
}
.checkCollectionStyle {
	color: #fffefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16.895px;
	font-style: normal;
	margin-top: 16px;
	font-weight: 700;
	line-height: normal;
	text-decoration-line: underline;
}
.card_level {
	font-family: "BerlinSansFBDemi";
	font-size: 48px;
	font-weight: bold;
	color: #fdd04b; /* Gold text color */
	text-shadow: 1px 1px 0 #914606, 2px 2px 0 #914606;
	padding: 10px;
	border-radius: 8px;
	display: inline-block;
	position: relative;
}

.card_level::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 0;
	bottom: 0;
	transform: translateX(-50%);
	z-index: 1;
}

.cardRateStyle {
	color: #fff;
	text-align: center;
	text-shadow: 0px 2.921px 2.921px rgba(0, 0, 0, 0.25);
	font-family: "Berlin Sans FB";
	font-size: 16.063px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 22.489px */
}
.expiredTime {
	color: #fff;
	text-align: center;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	font-family: "Berlin Sans FB";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 28px */
	letter-spacing: 1px;
	margin-top: 10px;
}
.backgroundiMG {
	position: absolute;
}
.iconStyle {
	top: 1%;
	position: absolute;
}
.cardRateStyle {
	top: 17%;
	position: absolute;
}
.textCardStyle {
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 105%; /* 18.9px */
}
.childStyle {
	position: absolute;
	top: 28%;
	display: flex;
	width: 100%;
	align-items: center;
	flex-direction: column;
}
.containerStyle {
	margin-top: 40px;
	border-radius: 12px;
	background: rgba(0, 0, 0, 0.3);
	width: 324px;
	height: 240px;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-content: center;
	flex-direction: column;
}
.rightStyle {
	top: 50%;
	right: 5%;
	position: absolute;
}
.leftStyle {
	top: 50%;
	left: 5%;
	position: absolute;
}
.card_level {
	position: absolute;
	top: 18%;
	font-family: "BerlinSansFBDemi";
	font-size: 18px;
	font-weight: bold;
	color: #fdd04b; /* Gold text color */
	text-transform: uppercase; /* Make all letters uppercase */
	letter-spacing: 2px; /* Adds spacing between the letters */
	text-shadow: 2px 2px 0 #5d3a07,
		/* Darker shadow behind the text for depth */ 3px 3px 0 #2d1d06; /* Slightly more shadow for emphasis */
	display: inline-block;
}
.centenStyle {
	color: #fff;
	text-align: start;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #00585c;
	font-family: "BerlinSansFBDemi";
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	justify-self: start;
	line-height: 105%; /* 18.9px */
}
.itemStyle {
	display: flex;
	padding: 0 30px;
	column-gap: 40px;
	margin-top: 10px;
	justify-content: space-around;
}
