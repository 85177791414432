.popup__usdt {
	text-align: center;
	&_title {
		color: #fff;
		text-align: center;
		-webkit-text-stroke-width: 0.5;
		-webkit-text-stroke-color: #b89b73;
		font-family: "BerlinSansFBDemi";
		font-size: 28px;
		font-style: normal;
		padding: 0 10px;
		font-weight: 700;
		margin-top: 18%;
		letter-spacing: 1.4px;
	}
	&_description {
		color: #fff;
		text-align: center;
		font-family: "BerlinSansFBDemi";
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.8px;
		margin-top: 20px;
		padding: 0 10%;
	}
	&_list_button {
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}
}
.text_button_quickly {
	color: #fffefe;
	text-align: center;
	-webkit-text-stroke-width: 1.0444444417953491;
	-webkit-text-stroke-color: #7d592a;
	font-family: "BerlinSansFBDemi";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.body {
	background: red;
}
.infoCard {
	margin-top: 16px;
	color: white;
	padding: 0 40px;
	ul {
		list-style-type: disc; // Bullet points
		margin-top: 10px;
	}

	li {
		&:last-child {
			margin-bottom: 8px; // Remove bottom margin for the last item
		}
	}
}
