.main {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.body {
	background-image: url("../../../assets/BgrPayment.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	max-width: 400px;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	flex-direction: column;
}
.infoCard {
	margin-top: 10px;
	padding: 0 40px;
	color: #fff;
	font-family: "Berlin Sans FB";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	max-width: 333px;
	ul {
		list-style-type: disc;
		margin-top: 10px;
	}

	li {
		&:last-child {
			margin-bottom: 8px;
		}
	}
}
.titleStyle {
	margin-top: 36px;
	color: #fff;
	text-align: center;
	font-family: "Berlin Sans FB";
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
}
.notice1 {
	padding: 10px 8px;
	justify-content: center;
	max-width: 340px;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
	z-index: 2000;
}
.paymentSuccessStyle {
	margin: 16px 46px;
	color: #fff;
	text-align: center;
	font-family: "Berlin Sans FB";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
}
