.main {
	display: flex;
	flex-direction: column;
}
.cardTypeStyle {
	color: #bfcadb;
	text-align: center;
	font-family: "Berlin Sans FB";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.floorPrice {
	color: #bfcadb;
	text-align: center;
	font-family: "Poppins";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.valueFloorPrice {
	color: #fff;
	font-family: "Poppins";
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.cardName {
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.indexStyle {
	overflow: hidden;
	color: #fff;
	text-overflow: ellipsis;
	font-family: "Berlin Sans FB";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.itemBody {
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: space-between;
	margin-top: 10px;
}
.custom_scroll {
	max-height: 70vh;
	overflow-y: auto;
	scrollbar-width: none;
}
.scrollable_div::-webkit-scrollbar {
	display: none;
}
