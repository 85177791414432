.body {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-top: 20px;
	background-image: url("../../../../../assets/images/ranking/BG.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	max-width: 400px;
	width: 100%;
	height: 100vh;
}
.bodyHeader {
	display: flex;
	height: 40px;
}
.mainItem {
	width: 94%;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE and Edge */
	border-radius: 16px;
	background: rgba(12, 43, 113, 0.7);
	height: 58vh;
	justify-content: center;
	align-items: center;
	overflow: auto; /* Cho phép scroll */
}
.mainItemEmpty {
	overflow-y: auto;
	padding: 16px;
	border-radius: 16px;
	border: 1px solid rgba(125, 153, 188, 0.5);
	background: linear-gradient(
		180deg,
		rgba(23, 39, 120, 0.4) 0%,
		rgba(39, 78, 189, 0.4) 100%
	);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
	backdrop-filter: blur(8px);
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.mainItem::-webkit-scrollbar {
	display: none;
}
.bodyItem {
	width: 100%;
	max-width: 400px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 16px;
	justify-content: space-between;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 14px;
	font-weight: 400;
}
.myRankingStyle {
	bottom: 12%;
	position: fixed;
	margin-top: 40px;
	border-radius: 8px;
	border: 1px solid #d5ffff;
	background: linear-gradient(180deg, #1e92d4 0%, #214abc 100%);
	background-size: contain;
	width: 100%;
	max-width: 400px;
	height: 44px;
	display: flex;
	justify-content: space-between;
	z-index: 100;
}
.imgMoreThan3 {
	margin-left: 8px;
	color: #fff;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #0b2067;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.slimeServerStyle {
	border-radius: 12px 0px 0px 0px;
	background: linear-gradient(135deg, #ffed4e 0%, #c36507 100.03%);
	display: flex;
	padding: 12px 24px;
	height: 44px;
	width: 190px;
	justify-content: center;
	align-items: center;
	color: #fff;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #956618;
	font-family: "BerlinSansFBDemi";
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.globalRankingStyle {
	border-radius: 0px 12px 0px 0px;
	background: rgba(0, 0, 0, 0.2);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
	display: flex;
	padding: 12px 24px;
	height: 50px;
	width: 190px;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-family: "BerlinSansFBDemi";
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.selected {
	background: linear-gradient(135deg, #ffed4e 0%, #c36507 100.03%);
}
.inSelected {
	background: rgba(0, 0, 0, 0.2);
}
.inviteMoreStyle {
	position: absolute;
	right: 18px;
	top: 18vh;
}
.wrapper {
	margin-top: 120px;
	text-align: center;
	padding: 30px 40px;
}
.copyMainStyle {
	position: relative;
	width: 300px;
	height: 60px;
	border-radius: 8px;
	border: 1px solid #3c376f;
	background: #3c376f;
	box-shadow: 0px 4px 4px 0px #2e286c inset;
}
.copyStyle {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	background: rgba(255, 243, 220, 0.1);
	width: 67.853px;
	height: 22.618px;
	flex-shrink: 0;
	right: 0;
	bottom: -8px;
	color: #fffefe;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 400;
}
.inviteLinkStyle {
	color: #b3b3b3;
	width: 90%;
	font-family: "Poppins";
	font-size: 13px;
	font-weight: 400;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.inviteUrlStyle {
	padding: 0 10px;
	justify-content: center;
	height: 52px;
	background-color: red;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	border-radius: 100px;
	border: 1px solid #3c376f;
	margin-bottom: 20px;
	box-shadow: 5px 5px 4px 0px #2e286c inset;
}
.referLinkStyle {
	color: var(--Color-Neutral-Grey70, #e0e0e0);
	font-family: "Poppins";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	margin-bottom: 2px;
}
.desReferLink {
	color: #fff;
	font-family: "BerlinSansFBDemi";
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	width: 260px;
	letter-spacing: 0.75px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.classRefer {
	top: 10px;
	left: 10px;
	width: 300px;
	margin-left: 10px;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
}
.confirmPurchaseTitle {
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #b89b73;
	font-family: "BerlinSansFBDemi";
	font-size: 28px;
	font-weight: 700;
	margin-bottom: 10px;
}
.confirmPurchaseTitle1 {
	margin-top: 14vh;
	position: absolute;
	top: 38vh;
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #b89b73;
	font-family: "BerlinSansFBDemi";
	font-size: 28px;
	font-weight: 700;
	margin-bottom: 10px;
}

.poolReward {
	padding: 16px 0;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	display: flex;
	align-items: center;
	span {
		background: linear-gradient(140deg, #ffed4e 52.58%, #c36507 93.39%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: "BerlinSansFBDemi";
		font-size: 20px;
		padding: 0 4px;
		font-weight: 700;
	}
}
.headerStyle {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 400px;
	height: 200px;
	margin-bottom: 16px;
}
.backStyle {
	left: 1%;
	position: absolute;
}
.nextStyle {
	right: 1%;
	position: absolute;
}
.timeLeftStyle {
	right: 10%;
	top: 60%;
	position: absolute;
	color: #fff;
	text-align: center;
	-webkit-text-stroke-color: #033090;
	font-family: "BerlinSansFBDemi";
	font-size: 11px;
	font-weight: 700;
}
.islandLevelStyle {
	position: absolute;
	left: 40%;
	top: 25%;
	color: #fff;
	text-align: center;
	font-family: "Berlin Sans FB";
	font-size: 11px;
	font-weight: 400;
}
.styleText {
	background: linear-gradient(135deg, #ffd700, #ff8c00);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-family: "BerlinSansFBDemi";
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.lastUpdateStyle {
	color: rgba(255, 255, 255, 0.8);
	text-align: center;
	font-family: "Poppins";
	font-size: 12px;
	font-style: italic;
	font-weight: 500;
	margin-bottom: 16px;
}
.infoStyle {
	right: 4px;
	position: absolute;
}
.infoStyle {
	position: absolute;
	top: 30%;
	right: 14%;
}
.nodataStyle {
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #b89b73;
	font-family: "BerlinSansFBDemi";
	font-size: 18px;
	font-weight: 700;
	padding: 0 16px;
	margin-top: 40px;
}
