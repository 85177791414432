/** @format */

.main {
	display: flex;
}
.headerStyle {
	margin-top: 40px;
	position: relative;
	height: 630px;
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
}
.cardStyle {
	margin-top: 176%;
	position: absolute;
	width: 100%;
	padding: 18px 1px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.bodyHeader {
	top: 44px;
	position: absolute;
	width: 92%;
	height: 560px;
	display: flex;
	align-items: center;
	justify-content: start;
	flex-direction: column;
	column-gap: 10px;
	padding-bottom: 4px;
	max-height: 630px;
	// overflow: auto;
}
.tagCardImg {
	position: absolute;
	top: -2px;
	width: 70%;
	height: 40px;
	left: 51%;
	transform: translateX(-50%);
}

.emptyCardStyle {
	width: 100%;
	height: 150px;
}
.headerCardText {
	margin: 16px 0;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 13px;
	font-weight: 400;
}
.progressBarContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 93%;
	height: 20px;
	background: #39346d;
	border-radius: 25px;
}

.progressBar {
	width: 100%;
	height: 100%;
	border-radius: 25px;
	position: absolute;
	border-radius: 38px;
	background: linear-gradient(130deg, #ffed4e -12.64%, #c36507 116.46%);
	top: 0;
	left: 0;
	overflow: hidden;
}

.progressText {
	position: absolute;
	z-index: 1;
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 0.800000011920929;
	-webkit-text-stroke-color: #39346d;
	font-family: "BerlinSansFBDemi";
	font-size: 11.375px;
	font-weight: 700;
	text-transform: uppercase;
}
.titleItem {
	color: #39346d;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
}
.imgItemStyle {
	top: -10px;
	position: absolute;
	width: 80px;
	height: 80px;
}
.item {
	padding: 6px 10px;
	margin-top: 16px;
	height: 200px;
	position: relative;
	justify-content: space-between;
	flex-direction: row;
	width: 96%;
	background: linear-gradient(90deg, #f0c694 0%, #fcddae 100%);
	display: flex;
	align-items: center;
}
.rightItem {
	width: 74%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.leftItem {
	width: 22%;
}
.leftItemText {
	margin-top: 66px;
	padding-bottom: 16px;
	width: 100%;
	display: flex;
	align-items: center;
	text-align: center;
	align-self: center;
	justify-self: center;
	justify-content: center;
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #39346d;
	font-family: "BerlinSansFBDemi";
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
}
.bodyHeader::-webkit-scrollbar {
	width: 0;
}
.tabsContainer {
	margin-bottom: 10px;
	display: flex;

	.tab {
		padding-left: 14px;
		padding-right: 14px;
		flex-grow: 1;
		text-align: center;
		cursor: pointer;
		color: rgba(255, 255, 255, 0.4);
		text-align: center;
		font-family: "Berlin Sans FB";
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.activeTab {
		padding-left: 14px;
		padding-right: 14px;
		color: #fff;
		border-bottom: 1.5px solid #fff;
		text-align: center;
		font-family: "Berlin Sans FB";
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
}
