@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.main {
	margin-top: 30px;
	position: relative;
	height: 88%;
	width: 100%;
	max-width: 400px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}
.backgroundiMG {
	position: absolute;
}
.cardRateStyle {
	top: 27%;
	position: absolute;
}
.closeBtn {
	position: absolute;
	width: 40px;
	height: 40px;
	top: 22%;
	right: 4px;
}
.childStyle {
	position: absolute;
	top: 26%;
	display: flex;
	width: 96%;
	align-items: center;
	flex-direction: column;
}
.startStyle {
	margin-left: 10px;
	color: #fff;
	font-family: "Berlin Sans FB";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 16px */
	text-align: left;
}
.itemStyle {
	height: 20px;
	display: flex;
	justify-content: space-between;
	width: 300px;
	align-items: center;
	margin-top: 6px;
}
.starStyleBold {
	margin-left: 10px;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%; /* 16px */
}
.titleSwitchAccount {
	color: var(--Color-Neutral-DarkMode-White, #fff);
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 110%; /* 26.4px */
	letter-spacing: 1.2px;
}
.desSwichAcc {
	color: var(--Color-Neutral-DarkMode-White, #fff);
	text-align: center;
	font-family: "Berlin Sans FB";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 16px */
	letter-spacing: 0.8px;
	padding: 16px 0;
}
.inputStyle {
	border-radius: 12px;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	width: 304px;
	height: 118px;
	padding: 12px 16px;
	align-items: flex-start;
	gap: 8px;
	flex-shrink: 0;
	color: var(--Color-Neutral-DarkMode-White, #fff);
	font-family: "Berlin Sans FB";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 18px */
	letter-spacing: 0.9px;
	margin-bottom: 16px;
}
.buttonLoginGoogle {
	border-radius: 108.5px;
	background: #071f54;
	display: flex;
	padding: 14.36px 18.7px;
	justify-content: center;
	align-items: center;
	margin: 10px 36px;
	align-self: stretch;

	p {
		color: #fff;
		text-align: center;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%;
	}
}