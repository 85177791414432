.dropdown {
	position: relative;
}

.dropdownToggle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px;
	border-radius: 8px;
	border: 1px solid #383275;
	background: #3c376f;
	box-shadow: 0px 3px 4px 0px #2e286c inset;
	width: 300px;
	column-gap: 10px;
	color: #fff;
	font-family: "Berlin Sans FB";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	margin-bottom: 6px;
}

.dropdownMenu {
	position: absolute;
	top: 100%;
	left: 0;
	border-radius: 8px;
	width: 100%;
	border: 1px solid #383275;
	background: #3c376f;
	box-shadow: 0px 3px 4px 0px #2e286c inset;
	margin: 0;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
	opacity: 0;
	color: #bccbfe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
}
.titleQuestStyle {
	border-radius: 4px 16px 0px 0px;
	background: #fff;
	display: block;
	width: max-content;
	margin-left: 20px;
	margin-top: 16px;
	padding: 8px 10px;
	text-align: center;
	color: #2344ae;
	font-family: "BerlinSansFBDemi";
	font-size: 18px;
	font-weight: 700;
}

.checkInDesStyle {
	color: #fff;
	margin-top: 2px;
	font-family: "Poppins";
	font-size: 14px;
	font-style: italic;
	font-weight: 300;
}

.dropdownMenu.open {
	max-height: 200px; /* Adjust based on number of items */
	opacity: 1;
}

.dropdownItem {
	padding: 10px;
	cursor: pointer;
}

.dropdownItem:hover {
	background-color: #f1f1f1;
}
.dividerStyle {
	height: 1px;
	width: 90%;
	margin-left: 5%;
	background: #516eb5;
	background-blend-mode: plus-lighter;
}
