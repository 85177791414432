.deposit {
	padding: 50px;
	margin-top: 16px;
	display: flex;
	align-items: center;
	align-content: center;
	flex-direction: column;
	row-gap: 16px;
	&__title {
		color: #fff;
		text-align: center;
		font-family: "Berlin Sans FB Demi";
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: 110%; /* 24.2px */
		letter-spacing: 1.1px;
	}
	&__content {
		color: #fff;
		text-align: center;
		font-family: "Berlin Sans FB";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 16px */
		letter-spacing: 0.8px;
		margin-top: 10px;
	}
	&__address {
		color: #fff;
		text-align: center;
		font-family: "Berlin Sans FB Demi";
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		display: flex;
		justify-content: start;
		align-items: center;
		gap: 10px;
		margin-top: 10px;
		margin-bottom: 20px;
	}
}
.copyStyle1 {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	background: rgba(255, 243, 220, 0.1);
	width: 67.853px;
	height: 22.618px;
	flex-shrink: 0;
	color: #fffefe;
	font-family: "Poppins";
	font-size: 14px;
	right: 10%;
	top: 40%;
	margin-top: 50px;
	font-weight: 400;
}
