/** @format */
html {
	scroll-behavior: smooth !important;
}
html,
body,
a {
	cursor: url("https://1.bp.blogspot.com/-qbWo9mPKO2Y/YL9utYdQBdI/AAAAAAAAFs4/mtjGu6u2uGwtJsT4gZG4lbhLV1a5lG6OQCLcBGAsYHQ/s0/mouse-f1.png"),
		auto;
}

a:hover {
	cursor: url("https://1.bp.blogspot.com/-nYv2dLl3oXY/YL9utYBCh8I/AAAAAAAAFtA/wII4lVw5w4k-4isGMY41heTqk8U4TJujgCLcBGAsYHQ/s0/mouse-f2.png"),
		auto;
}

button:hover {
	cursor: url("https://1.bp.blogspot.com/-nYv2dLl3oXY/YL9utYBCh8I/AAAAAAAAFtA/wII4lVw5w4k-4isGMY41heTqk8U4TJujgCLcBGAsYHQ/s0/mouse-f2.png"),
		auto !important;
}
image:hover {
	cursor: url("https://1.bp.blogspot.com/-nYv2dLl3oXY/YL9utYBCh8I/AAAAAAAAFtA/wII4lVw5w4k-4isGMY41heTqk8U4TJujgCLcBGAsYHQ/s0/mouse-f2.png"),
		auto !important;
}

a[type="button"] {
	cursor: url("https://1.bp.blogspot.com/-nYv2dLl3oXY/YL9utYBCh8I/AAAAAAAAFtA/wII4lVw5w4k-4isGMY41heTqk8U4TJujgCLcBGAsYHQ/s0/mouse-f2.png"),
		auto !important;
}

body {
	background-size: cover;
	background-position: right;
	background-repeat: no-repeat;
}

.fix-below-header {
	width: calc(var(--generalColumnWidth) - 6px);
	padding: 30px 10vw 30px;
	position: fixed;
	top: calc(var(--header-height));
	left: 50%;
	transform: translateX(calc(-50% - 4px));
	background: #fff;
	z-index: 2;
}

.add-background-silver-cloud {
	background-image: url("../assets/images/common/logo.png");
	background-size: cover;
	background-position: -3px;
}

.user-cursor {
	user-select: none;
	cursor: pointer;

	&:hover {
		opacity: 0.9;
	}
}

.page-flex {
	min-height: calc(100vh - var(--header-height));
	display: flex;
}

.main-column {
	width: var(--generalColumnWidth);
	background: #fff;
	position: relative;
	// overflow-y: auto;
	// overflow-x: hidden;
	// background: #fff;
}

.side-column {
	width: calc((100% - var(--generalColumnWidth)) / 2);
	height: 100%;
}

.screen_page_show {
	z-index: 999999999;
	position: fixed;
	height: 100%;
	width: 100%;
	display: flex;
	inset: 0;
}

.flex-grow-1 {
	flex-grow: 1;
}

[id$="__canvas-background"] {
	fill: transparent;
}
[id$="__canvas-background-group"] {
	stroke: transparent;
}

.wrap_canvas_writing_freelly {
	margin: 3vh auto 0px;
}

div[id^="draggable"] path {
	stroke-dasharray: 2;
}

.ending-game-wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 4vh 40px;
	h5.ending-game-title {
		color: #ffcb08;
		font-weight: 600;
		font-size: 22px;
		margin: 0 auto 15px;
	}

	img.ending-game-image {
		max-width: 300px;
		margin: 0px auto;
	}

	img.ending-game-star {
		max-width: 400px;
		margin: 0px auto;
	}

	p.ending-game-message {
		font-size: 18px;
		font-weight: 600;
		color: #828282;
		margin: 15px auto;
	}
}
.ending-game3-title {
	font-weight: 700;
	margin-bottom: 15px;
	color: #23ac38;
	font-size: 20px;
}

.ending-game3-image {
	margin: 0 auto;
}
.ending-game2-wrapper {
	text-align: center;
	padding: 20px;

	h5.ending-game2-title {
		font-weight: 600;
		margin-top: 5vh;
		font-size: 20px;
		margin-bottom: 5vh;
	}

	.list-word-wrapper {
		margin-top: 70px;
		position: relative;
		background: #fff;
		border-radius: 20px;
		max-height: 450px;
	}

	img.list-word-icon {
		position: absolute;
		width: 80px;
		left: 40px;
		height: auto;
		top: -55px;
	}

	.list-words {
		padding: 25px;
		max-height: 450px;
		overflow-y: scroll;

		.word-wrapper {
			display: flex;
			padding: 5px 0 10px 0;
			align-items: flex-start;

			img.word-check-icon {
				flex: 1;
				cursor: pointer;
				width: 20px;
			}

			img.word-check-icon:hover {
				opacity: 0.8;
			}

			p.word-content {
				flex: 13;
				text-align: left;
				padding-left: 20px;
				font-weight: 600;
			}

			p.word-trans {
				flex: 10;
				text-align: left;
				font-weight: 500;
			}
		}
	}

	.list-words::-webkit-scrollbar {
		width: 10px;
		border-radius: 10px;
	}

	/* Track */
	.list-words::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 10px;
	}

	/* Handle */
	.list-words::-webkit-scrollbar-thumb {
		background: #bbb;
		border-radius: 5px;
	}

	/* Handle on hover */
	.list-words::-webkit-scrollbar-thumb:hover {
		background: #aaa;
	}

	.mochi_primary_btn_nt.ending-game-btn {
		width: 200px;
		margin: 20px auto;
	}
}

.bold {
	font-weight: bold;
}
input[type="checkbox"] {
	box-sizing: border-box;
	width: 20px;
	height: 20px;
	margin: 6px;
	border-radius: 2px;
	appearance: none;
	outline: none;
	transition: outline 0.1s;
	padding: 10px;
}
input[type="checkbox"]:checked {
	padding: 10px;
}

input[type="checkbox"]:not(:disabled):checked {
	background-image: url("https://upload.wikimedia.org/wikipedia/commons/8/81/Check_mark_%28black%29.svg");
	background-size: 20px 20px;
}

@media screen and (max-width: 768px) {
	.ending-game-wrapper {
		padding-left: 16px;
		padding-right: 16px;

		h5.ending-game-title {
			font-size: 20px;
		}

		img.ending-game-image {
			width: 200px;
			height: auto;
		}
	}

	.ending-game2-wrapper {
		max-height: calc(100vh - 100px);

		h5.ending-game2-title {
			margin-top: 0;
			font-size: 17px;
		}

		.list-word-wrapper {
			max-height: 65vh;
		}

		.list-words {
			max-height: 65vh;
			padding-left: 0;
			padding-right: 0;
		}
	}

	.ending-game3-image {
		width: 200px;
		height: auto;
	}

	.ending-game-wrapper img.ending-game-star {
		max-width: 100%;
		height: auto;
	}
}
.active-btn {
	&:active {
		transform: scale(0.9);
		transition: .0.1s all ease-in-out;
	}
}
