/** @format */

.main {
	max-width: 390px;
}
.childStyle {
	margin-left: 10%;
	display: flex;
	margin-top: 30px;
	width: 390px;
	align-items: center;
	align-content: center;
}
.popup {
	width: 390px;
	background-image: url("../../../assets/images/account/backgrounSwap.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 580px;
	z-index: 1000;
	&__title {
		color: #fff;
		text-align: center;
		-webkit-text-stroke-width: 1;
		-webkit-text-stroke-color: #b89b73;
		font-family: "Berlin Sans FB Demi";
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: 90%; /* 19.8px */
		letter-spacing: 1.1px;
	}
	&__icon {
		position: absolute;
		top: -25%;
		left: 50%;
		transform: translateX(-50%);
	}
	&__content {
		margin-top: 120px;
	}
	&__list__button {
		margin: 30px auto;
	}
}
.button__close {
	position: absolute;
	top: -15px;
	right: 9%;
	cursor: pointer;
	z-index: 1000;
}

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Mờ phía sau */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 0;
}
.swapcointTile {
	margin-bottom: 40px;
	width: 80%;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.container {
	margin-bottom: 16px;
	width: 80%;
	display: flex;
	padding: 12px;
	margin-left: 10%;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	border-radius: 12px;
	background: rgba(0, 0, 0, 0.1);
}
.container1 {
	margin-bottom: 16px;
	width: 80%;
	display: flex;
	padding: 12px;
	justify-content: space-between;
	margin-left: 10%;
	flex-direction: row;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	border-radius: 12px;
	background: rgba(0, 0, 0, 0.1);
}
.swapHeaderStyle {
	color: #fff;
	font-family: "BerlinSansFBDemi";
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%;
	letter-spacing: 0.65px;
}
.inputStyle {
	color: #e0e0e0;
	font-family: "Berlin Sans FB";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 13px */
	letter-spacing: 0.65px;
	border-radius: 8px;
	border: 1px solid #383275;
	background: #3c376f;
	box-shadow: 0px 3px 4px 0px #2e286c inset;
	display: flex;
	height: 40px;
	padding: 12px 9px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
}

.inputStyle::placeholder {
	color: #e0e0e0;
	font-family: "Berlin Sans FB";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: 0.65px;
}
.aptStyle {
	display: flex;
	height: 40px;
	padding: 12px 9px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 8px;
	border: 1px solid #383275;
	background: #3c376f;
	box-shadow: 0px 3px 4px 0px #2e286c inset;
	color: #fff;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%;
	letter-spacing: 0.8px;
}
.mintStyle {
	color: #e0e0e0;
	background: #3c376f;
	font-family: "Berlin Sans FB";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 13px */
	letter-spacing: 0.65px;
}
.mintStyle1 {
	color: #e0e0e0;
	font-family: "Berlin Sans FB";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 13px */
	letter-spacing: 0.65px;
}
.aptStyle1 {
	color: #fff;
	font-family: "BerlinSansFBDemi";
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%; /* 13px */
	letter-spacing: 0.65px;
}
.amountBody {
	width: 96%;
	display: flex;
	justify-content: space-between;
	border-radius: 8px;
	border: 1px solid #383275;
	display: flex;
	height: 40px;
	padding: 12px 9px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	background: #3c376f;
	box-shadow: 0px 3px 4px 0px #2e286c inset;
}
.binanceStyle {
	color: #fff;
	font-family: "BerlinSansFBDemi";
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%;
	letter-spacing: 0.65px;
}
.feeeStyle {
	color: #fff;
	text-align: right;
	font-family: "Berlin Sans FB";
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 15px */
	letter-spacing: 0.75px;
}
.buttonStyle {
	margin-top: 60px;
	margin-left: 20%;
}
.maxStyle {
	border-radius: 1000px;
	background: #fff;
	color: #39346d;
	font-family: "Berlin Sans FB";
	font-size: 13px;
	display: flex;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 13px */
	letter-spacing: 0.65px;
	margin-left: 10px;
}.notice1 {
	display: inline-flex;
	padding: 10px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
	top: 50%;
	left: 8%;
	z-index: 2000;
}