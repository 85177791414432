.deposit {
	padding: 10px 50px;
	margin-top: 16px;
	display: flex;
	align-items: center;
	align-content: center;
	flex-direction: column;
	row-gap: 16px;
	&__title {
		color: #fff;
		text-align: center;
		font-family: "Berlin Sans FB Demi";
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: 110%; /* 24.2px */
		letter-spacing: 1.1px;
	}
	&__content {
		color: #fff;
		text-align: center;
		font-family: "Berlin Sans FB";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 16px */
		letter-spacing: 0.8px;
		margin-top: 10px;
	}
	&__address {
		color: #fff;
		text-align: center;
		font-family: "Berlin Sans FB Demi";
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		display: flex;
		justify-content: start;
		align-items: center;
		gap: 10px;
		margin-top: 10px;
		margin-bottom: 20px;
	}
}
.deposit1 {
	padding:50px;
	margin-top: 16px;
	display: flex;
	align-items: center;
	align-content: center;
	flex-direction: column;
	row-gap: 16px;
	&__title {
		color: #fff;
		text-align: center;
		font-family: "Berlin Sans FB Demi";
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: 110%; /* 24.2px */
		letter-spacing: 1.1px;
	}
	&__content {
		color: #fff;
		text-align: center;
		font-family: "Berlin Sans FB";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 16px */
		letter-spacing: 0.8px;
		margin-top: 10px;
	}
	&__address {
		color: #fff;
		text-align: center;
		font-family: "Berlin Sans FB Demi";
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		display: flex;
		justify-content: start;
		align-items: center;
		gap: 10px;
		margin-top: 10px;
		margin-bottom: 20px;
	}
}
.copyStyle1 {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	background: rgba(255, 243, 220, 0.1);
	width: 67.853px;
	height: 22.618px;
	flex-shrink: 0;
	color: #fffefe;
	font-family: "Poppins";
	font-size: 14px;
	right: 10%;
	top: 40%;
	margin-top: 50px;
	font-weight: 400;
}
.button {
	margin: 0 16px;
	display: flex;
	align-items: center;
	justify-content: start;
	padding: 13px 20px;
	width: 210px;
	border-radius: 25px;
	color: #fff;
	text-align: center;
	font-family: "Poppins";
	font-size: 15px;
	font-weight: 600;
	color: white;
	cursor: pointer;
	text-decoration: none;
	text-align: center;

	& img {
		margin-right: 10px;
		width: 24px;
		height: 24px;
	}
}

.metamask {
	background-color: black;
}

.stavax {
	background-color: #e6484f;
}
.checkedInStyle {
	display: inline-block;
	justify-self: center;
	align-self: center;
	padding: 10px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	width: 100%;
	max-width: 350px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
	top: 50%;
	left: 4%;
	z-index: 2000;
}

.ton {
	background-color: #4a90e2;
	color: #fff;
	position: relative;

	& span {
		color: #cfd8dc;
		font-weight: normal;
		font-size: 14px;
		margin-left: 5px;
	}
}
.paymentChoose {
	margin-top: 90px;
	color: #071f54;
	margin-bottom: 10px;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%; /* 24px */
}
