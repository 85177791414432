/** @format */

.loading__game {
	// background-image: url("../../../../../../assets/roll/loading/slime_bgc.jpg") !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	// height: 100vh;

	// position: fixed;
	// top: 0;
	// left: 0;
	// right: 0;
	// bottom: 0;
	// z-index: 1000;
}
.loading__game1 {
	// background-image: url("../../../../../../assets/roll/loading/slime_bgc.jpg") !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	background: rgba(0, 0, 0, 0.8);
	position: fixed;
	left: 0;
	padding-top: 14%;
	right: 0;
	bottom: 0;
	z-index: 1000;
}
// .loading__game {
// 	// background-image: url("../../../../../../assets/images/teleImage/loadingImg.webp") !important;
// 	background-position: center;
// 	background-repeat: no-repeat;
// 	background-size: cover;
// 	height: 100vh;
// 	display: flex;
// 	align-self: center;
// 	justify-self: center;
// 	align-items: center;
// 	flex-direction: column;
// 	justify-content: center;
// 	width: 100%;
// 	// background-image: url("../../../../assets/images/teleImage/bgc_sky_full.png") !important;
// 	background-position: center;
// 	background-repeat: no-repeat;
// 	background-size: cover;
// }
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	width: 368px;
	margin: auto;
	position: relative;

	border-radius: 10.74px;
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0px 2.148px 4.296px 0px rgba(0, 0, 0, 0.1);
}

.body {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	max-width: 400px;
	margin: auto;
}

.logo {
	width: 50px;
	height: 50px;
	margin-bottom: 20px;
}

.title {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 10px;
	color: #071f54;
}

.subtitle {
	font-size: 15px;
	margin-bottom: 20px;
	text-align: center;

	color: #080f5f;
	text-align: center;
	font-family: Poppins;
	font-size: 16.11px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}
.logoSignUp {
	width: 316px;
	position: absolute;
	top: -130px;
	left: 50%;
	transform: translateX(-50%);
}
.groupLoginBottom {
	margin-top: 13px;
	display: flex;
	justify-content: center;
	gap: 10px;

	img {
		width: 65px;
	}

	p {
		color: #010b5c;
		text-align: center;
		font-family: Poppins;
		font-size: 15.064px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
	}
}
.buttonLoginGoogle {
	border-radius: 108.5px;
	background: #071f54;
	display: flex;
	padding: 17.36px 21.7px;
	justify-content: center;
	align-items: center;
	gap: 10.85px;
	align-self: stretch;

	p {
		color: #fff;
		text-align: center;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%;
	}
}

.loginButton {
	background-color: #2c6a32;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	margin-bottom: 20px;
	transition: background-color 0.3s;

	&:hover {
		background-color: #255527;
	}
}

.iconList {
	display: flex;
	justify-content: space-between;
	width: 80%;
	margin: 0 auto;

	.iconItem {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 14px;
		color: #333;

		a {
			text-decoration: none;
			color: inherit;
			transition: color 0.3s;

			&:hover {
				color: #2c6a32;
			}
		}

		.icon {
			margin-bottom: 5px;
			font-size: 24px;
		}
	}
}
// Button.module.scss
.connectButton {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	border-radius: 26px;
	border: none;
	cursor: pointer;
	transition: background-color 0.3s ease;
	border: 1px solid black;
	margin: 10px;

	&:focus {
		outline: none;
		box-shadow: 0 0 0 3px rgba(0, 82, 255, 0.5);
	}

	img {
		margin-right: 10px;
		width: 24px;
		height: 24px;
	}
}

.connectCoinbaseButton {
	@extend .connectButton;
	background-color: #071f54;
	color: black;

	&:hover {
		background-color: #071f54;
	}

	&:active {
		background-color: #071f54;
	}
}

.connectMetamaskButton {
	@extend .connectButton;
	background-color: white;
	color: black;

	&:hover {
		background-color: #af641e;
	}

	&:active {
		background-color: #af641e;
	}
}
.aboutStyle {
	margin-top: 20px;
	display: flex;
	width: 100%;
	flex-direction: column;
	row-gap: 10px;
}
.loading {
	color: black;
	border: none;
	font-size: 16px;
	cursor: pointer;
}

.dot {
	animation: blink 1.4s infinite both;
}

.dot:nth-child(2) {
	animation-delay: 0.2s;
}

.dot:nth-child(3) {
	animation-delay: 0.4s;
}

@keyframes blink {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.closeStyle {
	margin-top: 10px;
	color: #071f54;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-decoration-line: underline;
}
