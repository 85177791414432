.button_back {
	background-image: url("../../../assets/roll/buttons/button_back.png");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 62px;
	height: 28px;

	display: flex;
	align-items: center;
	justify-content: center;

	&:active {
		transform: scale(0.9);
		transition: 0.2s ease-in-out;
	}
}

.button_normal_farming {
	background-image: url("../../../assets/roll/buttons/button_crystal_farming.svg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 238px;
	height: 60px;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;

	margin: 10px auto;

	&:active {
		transform: scale(0.9);
		transition: 0.2s ease-in-out;
	}
}

.button_crystal_farming {
	background-image: url("../../../assets/roll/buttons/button_nomal_farming.svg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 238px;
	height: 60px;

	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px auto;
	gap: 10px;

	&:active {
		transform: scale(0.9);
		transition: 0.2s ease-in-out;
	}
}
.button_ship_claim {
	background-image: url("../../../assets/roll/buttons/button_ship_claim.svg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 238px;
	height: 40px;

	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px auto;

	&:active {
		transform: scale(0.9);
		transition: 0.2s ease-in-out;
	}
}
.Button_claim_reward {
	background-image: url("../../../assets/roll/buttons/Button_claim_reward.svg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 175px;
	height: 49px;

	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px auto;

	&:active {
		transform: scale(0.9);
		transition: 0.2s ease-in-out;
	}
}
.Button_start_journey {
	background-image: url("../../../assets/roll/buttons/Button_start_journey.svg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 175px;
	height: 49px;

	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px auto;

	&:active {
		transform: scale(0.9);
		transition: 0.2s ease-in-out;
	}
}
.button_start_farming {
	background-image: url("../../../assets/roll/buttons/button_start_farming.png");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 175px;
	height: 49px;

	display: flex;
	align-items: center;
	justify-content: center;
	// margin: 5px auto;

	&:active {
		transform: scale(0.9);
		transition: 0.2s ease-in-out;
	}
}
.bgc_button_up_level {
	background-image: url("../../../assets/roll/buttons/yellow.png");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 239px;
	height: 49px;

	display: flex;
	align-items: center;
	justify-content: center;

	&:active {
		transform: scale(0.9);
		transition: 0.2s ease-in-out;
	}

	margin: 0 auto;


}

.bgc_button_up_level_text {
	color: #fffefe;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #7d592a;
	font-family: "Berlin Sans FB Demi";
	font-size: 21.6px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.button_claim {
	background-image: url("../../../assets/roll/buttons/button_claim.png");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 175px;
	height: 49px;

	display: flex;
	align-items: center;
	justify-content: center;

	&:active {
		transform: scale(0.9);
		transition: 0.2s ease-in-out;
	}
}

.button_disable_farming {
	background-image: url("../../../assets/roll/buttons/button_start_disable.png");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 175px;
	height: 49px;

	display: flex;
	align-items: center;
	justify-content: center;

	&:active {
		transform: scale(0.9);
		transition: 0.2s ease-in-out;
	}
}
.button_purple {
	background-image: url("../../../assets/roll/buttons/button_tim.png");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 239px;
	height: 49px;

	display: flex;
	align-items: center;
	justify-content: center;

	&:active {
		transform: scale(0.9);
		transition: 0.2s ease-in-out;
	}

	span {
		color: #fffefe;
		text-align: center;
		-webkit-text-stroke-width: 1.0444444417953491;
		-webkit-text-stroke-color: #595b93;
		font-family: "Berlin Sans FB Demi";
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;

		margin-bottom: 5px;
	}
}
