@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.modalOverlay {
	position: fixed;
	top: 0;
	width: 130%;
	height: 100%;
	max-width: 400px;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2000;
	background-image: url("../../../assets/images/card/BGMarket.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	left: 0%;
	animation: fadeIn 0.3s forwards;
}
.modalOverlay.show {
	opacity: 1;
	pointer-events: auto;
}
.main {
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 400px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	display: flex;
}
.tagCardImg {
	position: absolute;
	top: -44px;
	width: 64%;
	height: 80px;
	left: 50%;
	transform: translateX(-50%);
}
.closeBtn {
	position: absolute;
	width: 70px;
	height: 32px;
	top: 26px;
	left: 8px;
}
.cardMarket {
	position: absolute;
	height: 40px;
	top: 20px;
	left: 90px;
}
.rightItem {
	width: 70%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.leftItem {
	width: 25%;
}
.leftItemText {
	margin-top: 66px;
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #39346d;
	font-family: "BerlinSansFBDemi";
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
}
.bodyHeader::-webkit-scrollbar {
	width: 0;
}
.gallery {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	justify-content: center;
	overflow: auto;
}
.gallery::-webkit-scrollbar {
	width: 0;
}
.itemCard {
	position: relative;
	margin-top: 10px;
	margin: 0 20px;
}
.image {
	width: 150px;
	border: 4px solid white;
	border-radius: 10px;
}
.imageDisable {
	width: 150px;
	border: 4px solid white;
	border-radius: 10px;
	filter: grayscale(100%);
}
.startStyle {
	width: 28vw;
	max-width: 150px;
	display: flex;
	position: absolute;
	justify-content: center;
	top: -12px;
}

.statsCard {
	display: flex;
	flex-direction: column;
	background: #f5eacd;
	border-radius: 10px;
	padding: 20px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	background-image: url("../../../assets/images/card/DecorMarket.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	color: #fff;
	width: 89%;
	height: 140px;
	text-align: start;
	align-self: center;
	justify-content: center;
	margin-top: 18%;
}

.statsRow {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	color: #0a0000;
	text-align: center;
	font-family: "Poppins";
	font-size: 12px;
	align-items: start;
	font-weight: 400;
}

.statsItem {
	width: 45%;
	display: flex;
	align-items: center;
	column-gap: 4px;
	span {
		&:first-child {
			color: rgba(0, 0, 0, 0.4);
			text-align: center;
			font-family: "Poppins";
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
}
.grayStyle {
	color: rgba(255, 255, 255, 0.7);
	text-align: center;
	font-family: "Poppins";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
}
.marketplace {
	height: 70%;
	padding: 20px;
	border-radius: 10px;
	overflow: auto; /* Enable scrolling */
}

.marketplace::-webkit-scrollbar {
	display: none;
}

.marketplace {
	scrollbar-width: none; /* For Firefox */
}

.marketplace {
	-ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.card {
	position: relative;
	border-radius: 10px;
	display: flex;
	margin: 10px 0;
	align-items: self-start;
	justify-content: space-between;
}

.cardImage {
	width: 50px;
	height: 75px;
	border-radius: 5px;
}

.cardDetails {
	flex: 1;
	margin-left: 10px;
	color: #2d326a;
}

.cardDetails p {
	margin: 0;
	padding: 4px 0;
	align-items: center;
	display: flex;
	align-content: center;
}

.cardDetails p span:first-child {
	color: #fff;
	text-align: center;
	font-family: "Poppins";
	font-size: 16px;
	font-weight: 700;
	margin-right: 4px;
}

.cardDetails p span:last-child {
	padding-top: 1.4px;
	color: #fff;
	text-align: center;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 600;
}
.cardDetails .owner,
.cardDetails .price {
	display: flex;
}

.buyButton {
	background-color: #4caf50;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}
.priceStyle {
	margin-left: 4px;
	color: white;
	padding-bottom: 2px;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
}
.buyButton:hover {
	background-color: #45a049;
}

.buyStyle {
	right: 10px;
	position: absolute;
}
.listSuccessfulStyle {
	color: #fff;
	text-align: center;
	font-family: "Berlin Sans FB";
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	margin-bottom: 40px;
}
.stylespan {
	color: rgba(0, 0, 0, 0.4);
	text-align: center;
	font-family: "Poppins";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.stylespanLast {
	color: black;
	text-align: center;
	font-family: "Poppins";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
