@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

// .modalOverlay.show {
// 	opacity: 1;
// 	pointer-events: auto;
// }
.main {
	margin-top: 30px;
	position: relative;
	height: 88%;
	width: 100%;
	max-width: 400px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}
.titleDiv {
	position: absolute;
	top: 42%;
	width: 82%;
	height: 75px;
	flex-shrink: 0;
	background: rgba(0, 0, 0, 0.6);
	mix-blend-mode: plus-darker;
	align-items: center;
	align-content: center;
	padding: 0 6px;
	p {
		color: var(--Color-Neutral-DarkMode-White, #fff);
		text-align: center;
		font-family: "Berlin Sans FB";
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}
.viewTransaction {
	margin-top: 4px;
	color: var(--Color-Neutral-DarkMode-White, #fff);
	font-family: "Poppins";
	font-size: 11px;
	font-style: italic;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: 0.11px;
	text-decoration-line: underline;
}
.itemBuy {
	width: 136px;
	height: 166px;
	flex-shrink: 0;
	border-radius: 12px;
	border: 1px solid #1c81dc;
	background: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(2.5px);
}
.bodyBuy {
	column-gap: 10px;
	position: absolute;
	top: 55%;
	display: flex;
}
.closeBtn {
	position: absolute;
	width: 40px;
	height: 40px;
	top: 20%;
	right: 20px;
}
.titleRun {
	top: 34%;
	font-family: "Berlin Sans FB";
	font-size: 31.959px;
	position: absolute;
	text-align: center;
	font-weight: bold;
	color: #ffeb3b;
	text-shadow: -2px -2px 0 #ff4081, 2px -2px 0 #92278f, -2px 2px 0 #92278f,
		2px 2px 0 #92278f;
	text-align: center;
}
