.wrapper {
	// display: flex;
	// justify-content: space-between;
	// flex-direction: column;

	position: fixed;
	inset: 0;
	background-image: url("../../../../../../assets/images/teleImage/bgrFriend.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	max-width: 400px;
	z-index: 10000;

	margin: 0 auto;

	.group__chest {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		// margin-top: 210px;
		// margin-bottom: 160px;
	}

	.animation_start {
		animation: shake3 0.5s ease-in-out infinite;
	}

	.chest {
		margin-right: 15px;
		&:active {
			animation: shake 0.3s ease;
		}

		&_open {
			position: fixed;
			bottom: 70px;
		}
		display: block;
	}
	.textTap {
		margin: 53px auto;
		cursor: pointer;
		animation: animationTap 0.7s infinite;
		transition: transform 0.7s ease-in-out,
			background-color 0.7s ease-in-out;
	}
	.reward_gold {
		transform-style: preserve-3d;
		animation: flyUp 2s ease-out forwards;
	}
	.text_to_tab {
		animation: rotateText 1s linear;
	}

	.text__welcome {
		margin-top: 70px;
		animation: rotateText 1s linear;
		height: 360px;
		&__top {
			color: #002c5c;
			text-align: center;
			-webkit-text-stroke: 1px rgba(255, 255, 255, 0.9);
			font-family: "Berlin Sans FB Demi";
			font-size: 27.333px;
			font-style: normal;
			font-weight: 700;
			line-height: 150%;
		}
		&__below {
			color: #000;
			text-align: center;
			font-family: "Berlin Sans FB";
			font-size: 19px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%; /* 28.5px */
		}
	}

	.logo {
		display: flex;
		justify-content: center;
		margin-top: 60px;
		img {
			width: 123px;
			height: auto;
			object-fit: cover;
		}
	}
}

@media screen and (max-width: 400px) {
	.wrapper {
		.text__welcome {
			margin-top: 70px;
			animation: rotateText 1s linear;
			height: 360px;
			&__top {
				color: #002c5c;
				text-align: center;
				-webkit-text-stroke: 1px rgba(255, 255, 255, 0.9);
				font-family: "Berlin Sans FB Demi";
				font-size: 24.333px;
				font-style: normal;
				font-weight: 700;
				line-height: 150%;
			}
			&__below {
				color: #000;
				text-align: center;
				font-family: "Berlin Sans FB Demi";
				-webkit-text-stroke: .5px rgba(255, 255, 255, 0.9);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
			}
		}
		.text_to_tab {
			p {
				font-size: 24px;
			}
		}
		.logo {
			display: flex;
			justify-content: center;
			margin-top: 40px;
			img {
				width: 100px;
				height: auto;
				object-fit: cover;
			}
		}
	}
}

@keyframes animationTap {
	from {
		transform: translateY(-45px);
	}
	to {
		transform: translateY(0);
	}
}

@keyframes shake {
	0% {
		transform: translateX(-5px);
	}
	25% {
		transform: translateX(5px);
	}
	50% {
		transform: translateX(-5px);
	}
	75% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes shake2 {
	0% {
		transform: rotateX(0deg) rotateY(0deg);
	}
	25% {
		transform: rotateX(0deg) rotateY(10deg);
	}
	50% {
		transform: rotateX(0deg) rotateY(0deg);
	}
	75% {
		transform: rotateX(0deg) rotateY(-10deg);
	}
	100% {
		transform: rotateX(0deg) rotateY(0deg);
	}
}

@keyframes shake3 {
	0% {
		transform: rotate(-1deg);
	}
	25% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(1deg);
	}
	75% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-1deg);
	}
}

@keyframes flyUp {
	0% {
		transform: translateY(40vh) rotateX(0deg) rotateY(0deg);
		opacity: 0;
	}
	60% {
		transform: translateY(-20px) rotateX(0deg) rotateY(0deg);
		opacity: 0.5;
	}
	80% {
		transform: translateY(10px) rotateX(0deg) rotateY(0deg);
		opacity: 1;
	}
	100% {
		transform: translateY(0) rotateX(0deg) rotateY(0deg);
	}
}

@keyframes rotateText {
	0% {
		opacity: 0.3;
	}
	50% {
		opacity: 0.7;
	}
	100% {
		opacity: 1;
	}
}
