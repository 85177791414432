@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.main {
	margin-top: 30px;
	position: relative;
	height: 88%;
	width: 100%;
	max-width: 400px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}
.backgroundiMG {
	position: absolute;
}
.cardRateStyle {
	top: 27%;
	position: absolute;
}
.closeBtn {
	position: absolute;
	width: 40px;
	height: 40px;
	top: 16%;
	right: 20px;
}
.childStyle {
	position: absolute;
	top: 24%;
	display: flex;
	width: 78%;
	align-items: start;
	flex-direction: column;
}
.startStyle {
	margin-left: 10px;
	color: #fff;
	font-family: "Berlin Sans FB";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 16px */
	text-align: left;
}
.itemStyle {
	height: 20px;
	display: flex;
	justify-content: space-between;
	width: 300px;
	align-items: center;
	margin-top: 6px;
}
.starStyleBold {
	margin-left: 10px;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%; /* 16px */
}
.bodyPrvateKey {
	width: 380px;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: start;
	justify-items: start;
	border-radius: 8px;
	border: 1px solid #7d99bc;
	background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.4) 0%,
			rgba(0, 0, 0, 0.4) 100%
		),
		#2856b5;
	display: flex;
	width: 328px;
	padding: 16px;
	flex-direction: column;
	gap: 12px;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
	justify-content: center;
}
.privateKey {
	text-align: start;
	color: var(--Color-Neutral-DarkMode-White, #fff);
	font-family: "Berlin Sans FB";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.notice1 {
	display: inline-flex;
	padding: 10px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
	top: 50%;
	left: 38%;
	z-index: 2000;
}