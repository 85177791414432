/** @format */

.main {
	display: flex;
	flex-direction: column;
	padding: 16px;
	height: 92vh;
	overflow-y: scroll;
	scrollbar-width: none;
	background-image: url("../../../../../assets/images/teleImage/bgrFriend.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	margin-bottom: 20px;
}
.main2 {
	width: 100%;
	height: 92vh;
	position: relative;
}
.checkedInStyle {
	display: inline-block;
	justify-self: center;
	align-self: center;
	padding: 10px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
	top: 50%;
	left: 10%;
	z-index: 2000;
}
.main1 {
	height: 230px;
}
.headerStyle {
	margin-top: 40px;
	position: relative;
	height: 230px;
	width: 100%;
	display: flex;
}
.cardStyle {
	position: absolute;
	height: 190px;
	width: 100%;
}
.bodyHeader {
	z-index: 100;
	width: 100%;
	margin-top: 16px;
	flex-direction: column;
}
.itemHeader {
	position: absolute;
	height: 180px;
	width: 100%;
	max-width: 400px;
	display: flex;
	justify-content: center;
	column-gap: 10px;
}
.emptyCardStyle {
	height: 130px;
}
.tagCardImg {
	position: absolute;
	top: -20px;
	width: 70%;
	height: 40px;
	left: 51%;
	transform: translateX(-50%);
}
.headerCardText {
	margin-top: 10px;
	margin: 16px 0;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 13px;
	font-weight: 400;
}
.cardMintStyle {
	position: relative;
	height: 150px;
}
.countDownStyle {
	position: absolute;
	bottom: 47.6px;
	width: 100%;
	display: flex;
	align-items: center;
	color: white;
	justify-content: center;
	color: #fff;
	font-family: "BerlinSansFBDemi";
	font-size: 12px;
	font-weight: 400;
}
.statsCard {
	display: flex;
	flex-direction: column;
	color: #fff;
	width: 100%;
	margin-left: 20%;
	align-items: center;
}

.statsRow {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	color: #fff;
	text-align: center;
	font-family: "Poppins";
	font-size: 12px;
	align-items: start;
	font-weight: 400;
}

.statsItem {
	width: 100%;
	display: flex;
	align-items: center;
	column-gap: 4px;
	margin-bottom: 10px;
}
.mintcardBody {
	position: absolute;
	width: 100px;
	height: 100px;
	right: 10px;
	bottom: 0;
}
.mintCardM {
	position: relative;
	bottom: 20px;
}
.mintCardImg {
	position: absolute;
}
.mintCardText {
	position: absolute;
	color: #f50116;
	font-family: "Berlin Sans FB";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	bottom: -92px;
	margin-left: 6px;
}
.cardValue {
	position: absolute;
	right: 16px;
	top: -6px;
	color: #fff;
	text-align: center;
	text-shadow: 1px 2px 1px #a01b01;
	-webkit-text-stroke-width: 1.5;
	-webkit-text-stroke-color: #da5800;
	font-family: "BerlinSansFBDemi";
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.label {
	color: rgba(255, 255, 255, 0.4);
	text-align: center;
	font-family: "Poppins";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.value {
	color: #fff;
	font-family: "Poppins";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.notice1 {
	display: inline-flex;
	padding: 10px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
	top: 50%;
	left: 38%;
	z-index: 2000;
}
.notice3 {
	display: inline-flex;
	padding: 60px 8px;
	height: 40px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
	top: 50%;
	left: 5%;
	z-index: 2000;
}
