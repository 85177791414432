.main {
	display: flex;
	flex-direction: column;
	padding: 16px;
	height: 92vh;
	overflow-y: scroll;
	scrollbar-width: none;
	background-image: url("../../../../../assets/images/marketPlace/bgrMarket.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	margin-bottom: 20px;
	align-items: center;
}
.chooseCollectionStyle {
	height: 34px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	padding: 0 8px;
	justify-content: center;
	align-items: center;
	color: #fff;
	text-align: center;
	font-family: "Berlin Sans FB";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.switch_container {
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	background: rgba(0, 0, 0, 0.4);
	border-radius: 8px;
	position: relative;
	margin-left: 10px;

	.switch_button {
		width: 50%;
		height: 100%;
		position: absolute;
		transition: all 0.3s ease;
	}

	.switch_item {
		width: 50%;
		text-align: center;
		padding: 10px;
		color: #ffffff;
		z-index: 1;
		font-size: 14px;
		user-select: none;

		&.active {
			color: #303058;
			font-weight: bold;
		}
	}

	&.usd_active .switch_button {
		transform: translateX(50%);
		background: rgba(255, 255, 255, 0.4);
		border-radius: 0px 8px 8px 0px;
	}

	&.apt_active .switch_button {
		transform: translateX(-50%);
		background: rgba(255, 255, 255, 0.4);
		border-radius: 8px 0px 0px 8px;
	}
}
.cardTypeStyle {
	color: #bfcadb;
	text-align: center;
	font-family: "Berlin Sans FB";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
