/** @format */

.main {
	max-width: 400px;
}
.childStyle {
	margin-left: 10%;
	margin-top: 100px;
	width: 380px;
}
.popup {
	width: 390px;
	background-image: url("../../../assets/roll/bg_rectangle.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 50vh;
	z-index: 1000;
	&__title {
		color: #fff;
		text-align: center;
		-webkit-text-stroke-width: 1;
		-webkit-text-stroke-color: #b89b73;
		font-family: "Berlin Sans FB Demi";
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: 90%; /* 19.8px */
		letter-spacing: 1.1px;
	}
	&__icon {
		position: absolute;
		top: -25%;
		left: 50%;
		transform: translateX(-50%);
	}
	&__content {
		margin-top: 120px;
	}
	&__list__button {
		margin: 30px auto;
	}
}
.button__close {
	position: absolute;
	top: -15px;
	right: 9%;
	cursor: pointer;
	z-index: 1000;
}

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Mờ phía sau */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 0;
}
.titleSelection {
	color: #e0e0e0;
	font-family: "Berlin Sans FB";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: 0.65px;
	margin-bottom: 4px;
}
.inputStyle {
	border-radius: 8px;
	border: 1px solid #383275;
	background: #3c376f;
	box-shadow: 0px 3px 4px 0px #2e286c inset;
	display: flex;
	height: 40px;
	padding: 12px 10px;
	width: 130px;
	justify-content: space-between;
	align-items: center;
	color: #e0e0e0;
	font-family: "Berlin Sans FB";
	font-size: 13px;
	margin-right: 30px;
}
.contentStyle {
	color: rgba(255, 255, 255, 0.8);
	font-family: "Berlin Sans FB";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 12px */
	letter-spacing: 0.6px;
}
.listSuccessfulStyle {
	color: #fff;
	text-align: center;
	font-family: "Berlin Sans FB";
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	margin-bottom: 40px;
}
