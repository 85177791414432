/** @format */

.main {
	max-width: 390px;
}
.childStyle {
	display: flex;
	flex-direction: column;
	width: 390px;
	align-items: center;
	align-content: center;
}
.popup {
	width: 390px;
	background-image: url("../../../assets/roll/bg_rectangle.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 400px;
	z-index: 1000;
	&__title {
		color: #fff;
		text-align: center;
		-webkit-text-stroke-width: 1;
		-webkit-text-stroke-color: #b89b73;
		font-family: "Berlin Sans FB Demi";
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: 90%; /* 19.8px */
		letter-spacing: 1.1px;
	}
	&__icon {
		position: absolute;
		top: -25%;
		left: 50%;
		transform: translateX(-50%);
	}
	&__content {
		margin-top: 120px;
	}
	&__list__button {
		margin: 30px auto;
	}
}
.button__close {
	position: absolute;
	top: -15px;
	right: 9%;
	cursor: pointer;
	z-index: 1000;
}

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Mờ phía sau */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 0;
}
.swapcointTile {
	width: 100%;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 10px;
}
.container {
	margin-bottom: 16px;
	width: 80%;
	display: flex;
	padding: 12px;
	margin-left: 10%;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	border-radius: 12px;
	background: rgba(0, 0, 0, 0.1);
}
.container1 {
	margin-bottom: 16px;
	width: 80%;
	display: flex;
	padding: 12px;
	justify-content: space-between;
	margin-left: 10%;
	flex-direction: row;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	border-radius: 12px;
	background: rgba(0, 0, 0, 0.1);
}
.swapHeaderStyle {
	color: #fff;
	font-family: "BerlinSansFBDemi";
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%;
	letter-spacing: 0.65px;
}
.logoStyle {
	margin-top: 20px;
	width: 60px;
	margin-left: 42%;
	margin-bottom: 10px;
}
.itemLeft {
	color: var(--Color-Neutral-Grey70, #e0e0e0);
	font-family: "Berlin Sans FB";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
}
.itemRight {
	color: #fff;
	font-family: "Berlin Sans FB Demi";
	font-size: 16px;
	font-weight: 700;
}
.bodyItem {
	display: flex;
	margin-left: 16%;
	margin-bottom: 12px;
	width: 100%;
	display: flex;
	align-items: center;
	align-content: center;
	column-gap: 6px;
	justify-items: center;
}
