.popup {
	color: #fff;
	font-family: "BerlinSansFB";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%; /* 17.6px */
	letter-spacing: 0.8px;
    padding: 100px 50px;
}
