
.body {
    background-image: url("../../../assets/images/gameButtonCommon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    min-width: 150px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center
}
.bodyDisable {
    background-image: url("../../../assets/images/gameButtonCommonDisable.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    min-width: 150px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center
}
.body1 {
    background-image: url("../../../assets/images/imgButton.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    min-width: 150px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center
}
.childrenStyle {
    color: #FFFEFE;
    text-align: center;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #7D592A;
    font-family: "Berlin Sans FB Demi";
    font-size: 18px;
    font-weight: 700;
}
.childrenStyleDisable {
    color: white;
    text-align: center;
    font-family: "Berlin";
    font-size: 18px;
    font-weight: 700;
    -webkit-text-stroke-width: 1.5px;
}
.buttonStyle {
    background-image: url("../../../assets/images/teleImage/priceButtonImg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.textStyle {
    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin-left: 4px;
}