.main {
	width: 100%;
}
.card {
	position: relative;
	border-radius: 10px;
	display: flex;
	margin: 14px 0;
	align-items: self-start;
	justify-content: space-between;
}

.cardImage {
	width: 50px;
	height: 75px;
	border-radius: 5px;
}

.cardDetails {
	margin-top: 4px;
	flex: 1;
	margin-left: 10px;
	color: #2d326a;
}

.cardDetails p {
	margin: 0;
	align-items: center;
	display: flex;
	align-content: center;
}

.cardDetails p span:first-child {
	color: #fff;
	text-align: center;
	font-family: "Poppins";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	margin-right: 5px;
}

.cardDetails p span:last-child {
	color: #fff;
	font-family: "Poppins";
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.cardDetails .owner,
.cardDetails .price {
	display: flex;
}

.buyButton {
	background-color: #4caf50;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}
.priceStyle {
	margin-left: 4px;
	color: white;
	padding-bottom: 2px;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
}
.buyButton:hover {
	background-color: #45a049;
}

.buyStyle {
	right: 10px;
	position: absolute;
}
.listSuccessfulStyle {
	color: #fff;
	text-align: center;
	font-family: "Berlin Sans FB";
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	margin-bottom: 40px;
}
.editPriceStyle {
	color: #fff;
	font-family: "Poppins";
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-decoration-line: underline;
}
.listSuccessfulStyle {
	color: #fff;
	text-align: center;
	font-family: "Berlin Sans FB";
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	margin-bottom: 40px;
}
