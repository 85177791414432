@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.main {
	margin-top: 30px;
	position: relative;
	height: 88%;
	width: 100%;
	max-width: 400px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}
.backgroundiMG {
	position: absolute;
}
.cardRateStyle {
	top: 1%;
	position: absolute;
}
.closeBtn {
	position: absolute;
	width: 40px;
	height: 40px;
	top: 1%;
	right: 20px;
}
.childStyle {
	position: absolute;
	top: 12%;
	display: flex;
	width: 78%;
	align-items: start;
	flex-direction: column;
}
.itemStyle {
	display: flex;
	justify-content: space-between;
	width: 300px;
	align-items: center;
	align-content: start;
	margin-top: 6px;
	margin-top: 20px;
}
.startStyle {
	margin-left: 10px;
	color: #fff;
	font-family: "Berlin Sans FB";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 16px */
	text-align: left;
}
.starStyleBold {
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%; /* 16px */
}
