/* Button.module.css */
.button {
	/* Add your button styles here */
	background: linear-gradient(rgb(238, 202, 75) 0%, rgb(235, 107, 35) 100%);
	border-radius: 10px;
	border: 0px;
	font-weight: bold;
	padding: 16px;
	margin-top: 1rem !important;
	width: 100% !important;
	letter-spacing: 0.1em;
	font-size: 16px;
	color: white;
}
.buttonBlue {
	/* Add your button styles here */
	background: linear-gradient(
		rgb(54, 183, 238) 0%,
		rgba(31, 122, 230, 0.88) 100%
	);
	border-radius: 10px;
	border: 0px;
	font-weight: bold;
	padding: 16px;
	margin-top: 1rem !important;
	width: 100% !important;
	letter-spacing: 0.1em;
	font-size: 16px;
	color: white;
}

.button:hover {
	background-color: #0056b3;
}

.priceButtonStyle {
	background-image: url("../assets/images/teleImage/priceButton.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	background-color: transparent;
	justify-content: center;
	min-width: 87px;
	height: 30px;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 768px) {
	.button {
		font-size: 16px;
	}
	.buttonBlue {
		font-size: 16px;
	}
}
