/** @format */

.main {
    max-width: 400px;
}
.popup {
    width: 390px;
    background-image: url("../../../assets/roll/bg_rectangle.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50vh;
    z-index: 1000;
    &__title {
        color: #fff;
        text-align: center;
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: #b89b73;
        font-family: "Berlin Sans FB Demi";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 90%; /* 19.8px */
        letter-spacing: 1.1px;
    }
    &__icon {
        position: absolute;
        top: -25%;
        left: 50%;
        transform: translateX(-50%);
    }
    &__content {
        margin-top: 120px;
    }
    &__list__button {
        margin: 30px auto;
    }
}
.button__close {
    position: absolute;
    top: -15px;
    right: 9%;
    cursor: pointer;
    z-index: 1000;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Mờ phía sau */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
}
@media screen and (max-width: 375px) {
    .popup {
        height: 54vh;
    }
}
