.container {
	position: relative;
}
/** @format */
.main {
	background-image: url("../../../../../assets/images/teleImage/bgrFriend.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 20px;
	padding-bottom: 60px;
	height: 92vh;
	overflow-y: auto;
}
.main::-webkit-scrollbar {
	width: 0;
}
.cardTileStyle {
	margin-left: 30px;
	border-radius: 4px 13px 0px 0px;
	background: #fff;
	display: flex;
	padding: 6px 10px;
	color: #2344ae;
	font-family: "BerlinSansFBDemi";
	font-size: 15px;
	font-weight: 700;
	width: fit-content;
	margin-top: 20px;
}
.copyStyle {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	background: rgba(255, 243, 220, 0.1);
	width: 67.853px;
	height: 22.618px;
	flex-shrink: 0;
	color: #fffefe;
	font-family: "Poppins";
	font-size: 14px;
	right: 0;
	top: 22%;
	font-weight: 400;
}
.copyStyle1 {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	background: rgba(255, 243, 220, 0.1);
	width: 67.853px;
	height: 22.618px;
	flex-shrink: 0;
	color: #fffefe;
	font-family: "Poppins";
	font-size: 14px;
	right: 0;
	margin-top: 50px;
	font-weight: 400;
}
.inputStyle {
	border-radius: 16px;
	background: #2856b5;
	box-shadow: 0px 4px 4px 0px #192d87 inset;
	width: 340px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
	align-items: center;
}
.inputStyle1 {
	border-radius: 16px;
	background: #2856b5;
	box-shadow: 0px 4px 4px 0px #192d87 inset;
	width: 340px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 10px;
	color: #b59c9c;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
}
.titleStyle {
	color: #fff;
	text-align: center;
	font-family: "Poppins";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 2px;
}
.bodyHeader {
	margin: 0 16px;
	border-radius: 16px;
	border-radius: 16px;
	border: 2px solid #7d99bc;
	background: #2856b5;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	padding: 16px;
	position: relative;
	// row-gap: 10px;
}
.imgHeaderStyle {
	position: absolute;
	left: 3px;
	top: 12px;
}
.enableBtnStyle {
	padding: 16px 24px;
	background-image: url("../../../../../assets/images/teleImage/enableBtnImg.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	width: fit-content;
	height: fit-content;
	color: #fffefe;
	text-align: center;
	-webkit-text-stroke-width: 0.7201519012451172;
	-webkit-text-stroke-color: #7d592a;
	font-family: "BerlinSansFBDemi";
	font-size: 12px;
	font-weight: 700;
	min-width: 100px;
}
.disableBtnStyle {
	padding: 16px 24px;
	background-image: url("../../../../../assets/images/teleImage/disableBtnImg.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	width: fit-content;
	height: fit-content;
	color: #fffefe;
	text-align: center;
	min-width: 100px;
	-webkit-text-stroke-width: 0.7201519012451172;
	-webkit-text-stroke-color: #7d592a;
	font-family: "BerlinSansFBDemi";
	font-size: 12px;
	font-weight: 700;
}
.leftHeader {
	background-image: url("../../../../../assets/images/teleImage/emblem.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 70px;
	align-items: center;
	justify-content: center;
}
.valueHeader {
	color: var(--Color-Neutral-DarkMode-White, #fff);
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	margin-left: 20px;
}
.textStyle {
	color: var(--Color-Neutral-DarkMode-White, #fff);
	text-align: center;
	font-family: "Poppins";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.wrapper {
	margin-top: 100px;
	// text-align: center;
	padding: 0px 40px;
}
.copyMainStyle {
	position: relative;
	width: 80vw;
	height: 60px;
	border-radius: 8px;
	display: flex;
	border: 1px solid #3c376f;
	background: #3c376f;
	box-shadow: 0px 4px 4px 0px #2e286c inset;
	padding: 10px;
}
.copyStyle {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	background: rgba(255, 243, 220, 0.1);
	width: 67.853px;
	height: 22.618px;
	flex-shrink: 0;
	right: 0;
	bottom: -8px;
	color: #fffefe;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 400;
}
.inviteLinkStyle {
	color: #b3b3b3;
	width: 90%;
	font-family: "Poppins";
	font-size: 13px;
	font-weight: 400;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.inviteUrlStyle {
	padding: 0 10px;
	justify-content: center;
	height: 52px;
	background-color: red;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	border-radius: 100px;
	border: 1px solid #3c376f;
	margin-bottom: 20px;
	box-shadow: 5px 5px 4px 0px #2e286c inset;
}
.referLinkStyle {
	color: var(--Color-Neutral-Grey70, #e0e0e0);
	font-family: "BerlinSansFB";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.7px;
}
.desReferLink {
	color: #fff;
	font-family: "BerlinSansFBDemi";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.75px;
	width: 286px;
	letter-spacing: 0.75px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 8px;
}
.confirmPurchaseTitle {
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #b89b73;
	font-family: "BerlinSansFBDemi";
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 10px;
}
.confirmPurchaseTitle1 {
	margin-top: 14vh;
	position: absolute;
	top: 38vh;
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #b89b73;
	font-family: "BerlinSansFBDemi";
	font-size: 28px;
	font-weight: 700;
	margin-bottom: 10px;
}
.notice1 {
	display: inline-flex;
	padding: 10px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
	top: 50%;
	left: 42%;
	z-index: 2000;
}
.notice6 {
	display: inline-flex;
	padding: 10px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
	top: 20%;
	left: 0%;
	z-index: 2000;
}
.button_ask_now {
	color: #fffefe;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #7d592a;
	font-family: "Berlin Sans FB Demi";
	font-size: 21.6px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 5px;
}
.button_popup_add_now {
	margin: 20px auto;
	display: flex;
	justify-content: center;
}
.text_invite_count {
	color: var(--Color-Neutral-Grey70, #e0e0e0);
	font-family: Poppins;
	font-size: 14px;
	font-style: italic;
	font-weight: 500;
	letter-spacing: 0.14px;

	margin-top: 20px;
}

.item__account {
	width: 100%;
	display: flex;
	align-items: center;

	justify-content: space-between;
	border-bottom: 1px solid rgba(125, 153, 188, 0.8);
	padding: 10px 16px;

	&__left {
		display: flex;
		justify-content: start;
		gap: 20px;
		align-items: center;
	}

	&__icon {
		width: 30px;
		height: auto;
		object-fit: cover;
		&__copy {
			width: 25px;
			height: auto;
			object-fit: cover;
		}
	}
	&__text {
		color: #fff;
		text-align: center;
		font-family: "BerlinSansFBDemi";
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	&__withdraw {
		color: #fff;
		text-align: center;
		font-family: "BerlinSans";
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-decoration-line: underline;
	}
}

.wallet {
	&__connect {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		color: #ffff;
		padding: 10px 0px;
		flex-direction: row;
	}
	&__left {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		color: #fff;
		font-family: "BerlinSans";
		font-size: 16px;
		font-weight: 400;
	}
	&__eth {
		margin-top: 16px;
		display: flex;
		justify-content: start;
		align-items: center;
		color: #ffff;
		width: 100%;
		font-family: "BerlinSans";
		border-radius: 12px;
		border: 1px solid #7d99bc;
		background-blend-mode: multiply;
		p {
			margin-left: 10px;
		}
	}
}
.group__button {
	max-width: 255px;
	margin: 0 auto;
}
.title__wallet {
	color: #fff;
	text-align: center;
	text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
	font-family: "BerlinSansFBDemi";
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-top: 16px;
}
.title__content {
	color: #fff;
	text-align: center;
	font-family: "BerlinSans";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 30px;
	margin-top: 10px;
}
.actionTextStyle {
	color: #fff;
	font-family: "Berlin Sans FB";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.mainActionStyle {
	display: flex;
	column-gap: 40px;
}
.bodyActionStyle {
	row-gap: 4px;
	flex-direction: column;
	align-items: center;
	align-content: center;
	display: flex;
}
.titleAptos {
	color: #fff;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 4px;
}
.coinStyle {
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
	width: 86%;
}
.notice1 {
	display: inline-flex;
	padding: 10px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
	top: 50%;
	left: 38%;
	z-index: 2000;
}
.notice2 {
	display: inline-flex;
	padding: 10px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 16px;
	font-weight: 400;
	top: 50%;
	left: 30%;
	z-index: 2000;
}
.buttonLoginGoogle {
	border-radius: 108.5px;
	background: #071f54;
	display: flex;
	padding: 17.36px 21.7px;
	justify-content: center;
	align-items: center;
	gap: 10.85px;
	margin: 10px 16px;
	align-self: stretch;

	p {
		color: #fff;
		text-align: center;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%;
	}
}