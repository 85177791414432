@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.modalOverlay {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	max-width: 400px;
	background-color: rgba(12, 11, 11, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2000;
	background-image: url("../../../assets/images/card/bgrMarket.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	animation: fadeIn 0.3s forwards;
}
.modalOverlay.show {
	opacity: 1;
	pointer-events: auto;
}
.main {
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 400px;
	background-color: rgba(12, 11, 11, 0.5);
	align-items: center;
	justify-content: center;
	flex-direction: column;
	display: flex;
}
.body {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	row-gap: 20px;
}
.tagCardImg {
	position: absolute;
	top: -44px;
	width: 64%;
	height: 80px;
	left: 50%;
	transform: translateX(-50%);
}
.closeBtn {
	position: absolute;
	width: 40px;
	height: 32px;
	top: 20%;
	right: 20px;
}
.titleCard {
	position: absolute;
	top: -28px;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 20px;
	font-weight: 700;
}

.progressBarContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	height: 20px;
	background: #39346d;
	border-radius: 25px;
}

.progressBar {
	width: 100%;
	height: 100%;
	border-radius: 25px;
	position: absolute;
	border-radius: 38px;
	background: linear-gradient(130deg, #ffed4e -12.64%, #c36507 116.46%);
	top: 0;
	left: 0;
	overflow: hidden;
}

.titleTEXT {
	margin-top: 30px;
	color: #fff;
	text-align: center;
	font-family: "BerlinSansFBDemi";
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: 110%; /* 24.2px */
	letter-spacing: 1.1px;
	margin-bottom: 10px;
}
